import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import className from 'classnames'

const DualSlide = ({height, initValue, conditions, children, freeHeight}) => {
  const $grandSlide = React.useRef()
  const [tly, tlySet] = React.useState(initValue)

  const onScroll = () => {
    const {top, height} = $grandSlide.current.getBoundingClientRect()
    const ratio = (top / height) * -1

    conditions({ratio, tlySet})
  }

  React.useEffect(() => {
    if (conditions) {
      window.addEventListener('scroll', onScroll)

      return () => {
        window.removeEventListener('scroll', onScroll)
      }
    }
  }, [])

  return (
    <div
      className='DualSlide'
      ref={$grandSlide}
      style={{
        height,
        '--DualSlide-tly-l': `${get(tly, '0', 0)}%`,
        '--DualSlide-tly': `${get(tly, '1', 0)}%`,
      }}
    >
      <div
        className={className('DualSlide__contents', {
          'DualSlide__contents--free-height': freeHeight,
        })}
      >
        {children}
      </div>
    </div>
  )
}

DualSlide.propTypes = {}

export default DualSlide
